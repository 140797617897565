import { Spin } from "antd";
import React from "react";
import "../dashboardPage.scss";

function WidgetLoadingSpin() {
  return (
    <div className={"dashboard-widget-loading"}>
      <Spin tip={"Loading..."} />
    </div>
  );
}
export default WidgetLoadingSpin;
