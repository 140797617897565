import React, { useMemo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Button, Dropdown, Badge } from "antd";
import { useModal } from "../modalContext/ModalContext";
import { useUser } from "../userContext/UserContext";
import { Utils } from "../../Utils";
import { URL, ACL_ROLE, ENV } from "../../Constants";
import { removeExternalUserId } from "../../OneSignal";

import "./MainHeader.scss";

export default function MainHeader() {
  const { t } = useTranslation("common");

  const { userRes } = useUser();
  const { pathname } = useLocation();
  const modal = useModal();

  const [title, setTitle] = useState("-");
  const [subTitle, setSubTitle] = useState("???");

  let tenantName = userRes.data?.tenant.name;

  useEffect(() => {
    if (pathname.startsWith("/main/head/control")) {
      setTitle(t("mainPage.title"));
      setSubTitle(t("mainPage.titleDesc"));
    } else if (pathname.startsWith("/main/head/insights")) {
      setTitle(t("insightsPage.title"));
      setSubTitle(t("insightsPage.titleDesc"));
    } else if (pathname.startsWith("/main/head/dashboard")) {
      setTitle(t("dashboardPage.title"));
      setSubTitle(t("dashboardPage.titleDesc"));
    } else if (pathname.startsWith("/main/head/location")) {
      setTitle(t("locationPage.title"));
      setSubTitle(t("locationPage.titleDesc"));
    } else if (pathname.startsWith("/main/head/schedule")) {
      setTitle();
      setSubTitle();
    } else if (pathname.startsWith("/main/head/account")) {
      setTitle(t("accountPage.title"));
      setSubTitle(t("accountPage.titleDesc"));
    } else if (pathname.startsWith("/main/head/scene")) {
      setTitle(t("scenePage.title"));
      setSubTitle(t("scenePage.titleDesc"));
    } else if (pathname.startsWith("/main/head/profile")) {
      setTitle(t("profile.title"));
      setSubTitle(t("profile.titleDesc"));
    } else if (pathname.startsWith("/main/head/alert")) {
      setTitle(t("alertsPage.title"));
      setSubTitle(t("alertsPage.titleDesc"));
    } else {
      setTitle("");
      setSubTitle("");
    }
  }, [pathname]);

  const navigate = useNavigate();
  function goProfile() {
    navigate("/main/head/profile");
  }
  function goAlert() {
    navigate("/main/head/alert");
  }
  function logout() {
    Utils.clearSignInInfo();
    removeExternalUserId();
    window.location = URL + "auth/signout";
  }
  const items = [
    {
      key: "Edit Profile",
      label: (
        <Button onClick={goProfile} type="link" size="small">
          {t("profile.editProfile")}
        </Button>
      )
    },
    {
      key: "Logout",
      label: (
        <Button type="link" size="small" onClick={logout}>
          {t("profile.signOut")}
        </Button>
      )
    }
  ];

  function onChangeTenant() {
    modal.open({ id: "TenantModal", isOpen: true, userRes });
  }

  console.log("MainHeader");

  return (
    <header className="mainHeader container-fluid">
      <div className="h-100 row align-items-center">
        <div className="col-auto">
          <div className="icon shortLogo p-4"></div>
        </div>
        <div className="col">
          <div className="bold biggest uppercase">{title}</div>
          <div className="small">{subTitle}</div>
        </div>
        {(ENV === "local" || ENV === "dev") && ( //TODOricky remove ENV check
          <div className="col-auto ps-5 pe-0 bold" onClick={goAlert}>
            <Badge count={0} showZero color={"#FE0F0F"}>
              <button className="p-3 btn icon round alerts"></button>
            </Badge>
          </div>
        )}
        {Utils.hasMinAclRole(userRes.data, ACL_ROLE.super) && (
          <div className="col-auto">
            <button className="p-2 btn plain white" onClick={onChangeTenant}>
              {tenantName}
            </button>
          </div>
        )}
        <div className="col-auto pe-4">
          <Dropdown overlayClassName="drop-down-container" menu={{ items }} placement="bottom">
            <button className="p-3 btn icon round profile"></button>
          </Dropdown>
        </div>
      </div>
    </header>
  );
}
