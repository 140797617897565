import { Navigate } from "react-router-dom";

import { Utils } from "../../Utils";
import { useUser } from "../userContext/UserContext";
import Spinner from "../spinner/Spinner";

export default function RouteElmWrapper({ minRole, requiredRoles, isTep, isEms, children }) {
  console.log("RouteElmWrapper");

  const { userRes } = useUser();
  let hasRight = false;
  if (minRole) {
    hasRight = Utils.hasMinAclRole(userRes.data, minRole);
  }
  if (requiredRoles?.length) {
    for (let i = 0; i < requiredRoles.length; i++) {
      if (Utils.isAclRole(userRes.data, requiredRoles[i])) {
        hasRight = true;
        break;
      }
    }
  }
  if (hasRight && isTep) {
    hasRight = userRes.data?.tenant?.enableTep;
  }
  if (hasRight && isEms) {
    hasRight = userRes.data?.tenant?.enableEms;
  }

  if (userRes.loading || !userRes.data) {
    return <Spinner isCenter={true} height={"15rem"} />;
  } else if (hasRight) {
    return children;
  } else {
    console.log("no access right, redirect to home");
    return <Navigate to="/main/head/home" />;
  }
}
