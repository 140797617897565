import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";
import { useModal } from "../../modalContext/ModalContext";

import { DEVICE_ACTION } from "../../../Constants";
import { useMutationErrHandler, toggleFloorMutation } from "../../../Gql";
import "./FloorActionPanel.scss";

export default function FloorActionPanel({ zone }) {
  console.log("FloorActionPanel");

  const { t } = useTranslation("common");
  const modal = useModal();

  const [toggleFloor, toggleFloorData] = useMutation(toggleFloorMutation);
  const errHandler = useMutationErrHandler();

  const [action, setAction] = useState(DEVICE_ACTION.on);

  function handleChangeAction(e) {
    setAction(e.target.value);
  }

  function handleFloorAction() {
    toggleFloor({ variables: { id: zone.id, state: action } }).then(action === DEVICE_ACTION.on ? modal.turnOnMsg : modal.turnOffMsg, errHandler);
  }

  return (
    <div className="floorActionPanel py-35 px-45">
      <div className="small mb-3">{t("lbl.floorAlertDesc")}</div>
      <div className="row align-items-end">
        <div className="col">
          <div>
            <label className="pt-2">
              <input
                className="align-middle form-check-input yellow"
                onChange={handleChangeAction}
                value={DEVICE_ACTION.on}
                checked={action === DEVICE_ACTION.on}
                name="action"
                type="radio"
              />
              <span className="col ps-2">{t("lbl.allOn")}</span>
            </label>
          </div>
          <div>
            <label className="pt-3">
              <input
                className="align-middle form-check-input yellow"
                onChange={handleChangeAction}
                value={DEVICE_ACTION.off}
                checked={action === DEVICE_ACTION.off}
                name="action"
                type="radio"
              />
              <span className="col ps-2">{t("lbl.allOff")}</span>
            </label>
          </div>
        </div>
        <div className="col-auto">
          <button className="btn white w6 me-3" onClick={modal.close}>
            {t("lbl.cancel")}
          </button>
          <button className="btn yellow w6" onClick={handleFloorAction} disabled={!zone}>
            {t("lbl.ok")}
          </button>
        </div>
      </div>
    </div>
  );
}
