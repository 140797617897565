import { useState } from "react";

import "./SwitchBtn.scss";

//share use
export default function SwitchBtn({ onChange, checked, disabled, className, data }) {
  console.log("SwitchBtn");

  const [value, setValue] = useState(checked);
  const [timerVal, setTimerVal] = useState(null);
  const [showUserValue, setShowUserValue] = useState(false);

  const cls = className ? className + " " : "";

  function handleOnChange(e) {
    setValue(e.target.checked);
    onChange(e, data);
    setShowUserValue(true);
    clearTimeout(timerVal);
    setTimerVal(
      setTimeout(() => {
        setShowUserValue(false);
      }, 1000)
    );
  }

  return (
    <div className={cls + "switchBtn form-check form-switch"}>
      <input checked={showUserValue ? value : checked} disabled={disabled} onChange={handleOnChange} className="form-check-input" type="checkbox" role="switch" />
    </div>
  );
}
