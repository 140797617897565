import React, { useEffect, useState, useRef, createContext, useContext } from "react";
import { Outlet } from "react-router-dom";

const ScrollEventContext = createContext();

export function ScrollEventProvider({ triggerScrollEnd, inIframe, children }) {
  console.log("PageLayoutScroller");

  const scrollRef = useRef(null);
  const [scrollEndEvent, setScrollEndEvent] = useState(null);

  useEffect(() => {
    if (triggerScrollEnd && scrollRef?.current) {
      const handler = event => {
        if (event.target.scrollTop + event.target.offsetHeight > event.target.scrollHeight - 50) {
          setScrollEndEvent({ id: new Date().getTime() });
        }
      };
      scrollRef.current.addEventListener("scroll", handler);
      return () => {
        scrollRef?.current?.removeEventListener("scroll", handler);
      };
    }
  }, [triggerScrollEnd, scrollRef]);

  return (
    <div ref={scrollRef} className={"overflow-y-auto " + (inIframe ? "h-100" : "flex-fill-remain")}>
      <ScrollEventContext.Provider value={{ scrollEndEvent }}>{children}</ScrollEventContext.Provider>
    </div>
  );
}

export function useScrollEvent() {
  return useContext(ScrollEventContext);
}
