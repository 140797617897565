import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/client";

import { useMutationErrHandler, useNodes, selectWorkstationMutation, turnOffWorkstationMutation, turnOnWorkstationMutation } from "../../../Gql";
import ModalLayout from "../../modalLayout/ModalLayout";
import SearchBox from "../../searchBox/SearchBox";
import FloorList from "../../floorList/FloorList";
import Dropdown from "../../dropdown/Dropdown";
import MapToggle from "../../mapToggle/MapToggle";
import FloorPlan from "../../floorPlan/FloorPlan";
import NodeRow from "../nodeRow/NodeRow";
import Spinner from "../../spinner/Spinner";
import { useModal } from "../../modalContext/ModalContext";
import { LIST_TYPE, DEFAULT_TEMP_RANGE } from "../../../Constants";
import "./SelectWsModal.scss";

//if have wsRes, switch ws case, otherwise it is select ws case
export default function SelectWsModal({ isOpen, userRes, wsRes, buildingsRes, resolve, reject }) {
  console.log("SelectWsModal");
  if (userRes?.data == null || buildingsRes?.data == null) {
    console.error("must loaded userRes and buildingsRes before opening this popup");
  }

  const { t } = useTranslation("common");
  const modal = useModal();

  const items = [
    { id: LIST_TYPE.fixdesk, name: t("nodeType.WORKSTATION") },
    { id: LIST_TYPE.hotdesk, name: t("nodeType.HOTDESK") }
  ];

  const [searchText, setSearchText] = useState("");
  const [isByMap, setIsByMap] = useState(false);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedListTypeObj, setSelectedListTypeObj] = useState(wsRes?.data ? items[1] : items[0]);
  const [wsSelected, setWsSelected] = useState(false);
  const [saving, setSaving] = useState(false);

  const nodesRes = useNodes(selectedZone ? selectedZone.id : null, selectedListTypeObj.id, false, true);
  const filteredList = useMemo(() => {
    let list = [];
    if (nodesRes.data) {
      list = nodesRes.data;
      if (searchText) {
        list = list.filter(n => n.name.toLowerCase().includes(searchText));
      }
    }
    return list;
  }, [nodesRes, searchText]);

  const [turnOnWs] = useMutation(turnOnWorkstationMutation);
  const [turnOffWs] = useMutation(turnOffWorkstationMutation);
  const [selectWs, selectWsData] = useMutation(selectWorkstationMutation);
  const errorHandler = useMutationErrHandler();

  function handleSelectWs(node) {
    if (node.id === selectedNodeId) {
      setSelectedNodeId(null);
    } else {
      setSelectedNodeId(node.id);
    }
  }

  function handleConfirmSelectWs() {
    setSaving(true);
    if (wsRes?.data) {
      turnOffWs({ variables: { id: wsRes.data.id } }).then(null, errorHandler);
    }
    selectWs({ variables: { id: selectedNodeId } }).then(() => {
      setWsSelected(true);
      modal.close();
    }, errorHandler);
    turnOnWs({ variables: { id: selectedNodeId } }).then(null, errorHandler);
  }

  function handleAfterClose() {
    if (resolve) {
      resolve(wsSelected);
    }
  }

  useEffect(() => {
    setSelectedNodeId(null);
  }, [isByMap, selectedZone, selectedListTypeObj]);

  return (
    <ModalLayout isOpen={isOpen} modalContext={modal} modalContentClassName="p-3" modalClassName={"selectWsModal"} onAfterClose={handleAfterClose}>
      <div className="row align-items-center mx-0 pb-3 underline greyerBorder">
        <div className="col ps-0 mainFont order-sm-1">
          <div className="bold big uppercase">{wsRes?.data ? t("mainPage.switchWs") : t("mainPage.selectWs")}</div>
          <div className="small">{t("mainPage.selectWsDesc")}</div>
        </div>
        {userRes.data && userRes.data.tenant.enableFloorPlanSelection && <MapToggle className="col-auto order-sm-3" isMap={isByMap} onChange={setIsByMap} />}
        <div className="col-auto pe-0 order-sm-4">
          <button className="btn icon crossThin p-3" onClick={modal.close}></button>
        </div>
        <div className="col-12 px-0 mt-2 col-sm-auto px-sm-25 mt-sm-0 order-sm-2">
          <SearchBox className="yellowBorder w-100 w8-sm focus-sm-w12 focus-md-w16" onChange={setSearchText} />
        </div>
      </div>
      <div className="row align-items-center my-3">
        <div className="col col-xl-8">
          <FloorList onChange={setSelectedZone} buildingsRes={buildingsRes} defaultZoneId={wsRes?.data?.zoneId} />
        </div>
        {selectedZone && (
          <div className="col-lg-auto col-12 col-lg-auto mt-3 mt-lg-0">
            <div className="row align-items-center">
              <div className="col-auto d-none d-md-block light">{t("lbl.type")}</div>
              <div className="col-12 col-md-auto minw12">
                <Dropdown valueObj={selectedListTypeObj} options={items} onChange={setSelectedListTypeObj} handleColor="yellow" className="main yellowBorder capital" />
              </div>
            </div>
          </div>
        )}
      </div>
      {selectedZone &&
        (isByMap ? (
          <div className="d-flex flex-column flex-fill-remain">
            <div className="flex-fill-remain">
              <FloorPlan
                zone={selectedZone}
                nodes={nodesRes.loading ? null : filteredList}
                coolTemp={DEFAULT_TEMP_RANGE.cool}
                warmTemp={DEFAULT_TEMP_RANGE.warm}
                selectedNodeId={selectedNodeId}
                onClick={handleSelectWs}
              />
            </div>
            {selectedNodeId && (
              <div className="mt-3 text-right">
                <button disabled={saving} className="btn yellow w8 bold" onClick={handleConfirmSelectWs}>
                  {t("lbl.select")}
                </button>
              </div>
            )}
          </div>
        ) : nodesRes.loading ? (
          <Spinner isCenter={true} height={"4rem"} />
        ) : (
          <div className="overflow-y-auto p-1">
            {filteredList.map(n => (
              <div key={n.id}>
                <NodeRow node={n} listType={selectedListTypeObj.id} onClick={handleSelectWs} />
                {n.id === selectedNodeId && (
                  <div className="mainCard p-35 mb-1 text-right">
                    <button disabled={saving} className="btn yellow w8 bold" onClick={handleConfirmSelectWs}>
                      {t("lbl.select")}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
    </ModalLayout>
  );
}
