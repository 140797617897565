import { useTranslation } from "react-i18next";
import "./SearchBox.scss";

//share use
//onChange(text)
export default function SearchBox({ onChange, delay, className }) {
  console.log("SearchBox");

  const { t } = useTranslation("common");
  const timerDelay = delay == null ? 400 : delay;

  let timer = null;

  function onTextChange(e) {
    clearTimeout(timer);
    timer = setTimeout(() => onChange((e.target.value || "").toLowerCase()), timerDelay);
  }

  return (
    <div className={className ? className + " searchBox" : "searchBox"}>
      <div className="icon search p-2"></div>
      <input className="w-100 inputBox round" onChange={onTextChange} placeholder={t("lbl.search")} />
    </div>
  );
}
