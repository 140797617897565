export const VERSION = "1.0.0";

export const ENV = (function () {
  let tmp = window.location.origin.split("//");
  tmp = tmp[1] ? tmp[1].toLowerCase() : "";
  if (tmp.startsWith("localhost") || tmp.startsWith("127.0.0.1")) {
    return "local";
  } else if (tmp.startsWith("dev.")) {
    return "dev";
  } else {
    return "prod";
  }
})();

export const TEP_DOMAIN = (function () {
  if (ENV === "local") {
    return "http://localhost:8080/#!/";
  } else if (ENV === "dev") {
    return "https://dev.portal.en-trak.com/#!/";
  } else {
    return "https://portal.en-trak.com/#!/";
  }
})();

export const URL = (function () {
  if (ENV === "local") {
    return "https://dev.api.en-trak.com/";
  }
  if (ENV === "dev") {
    return "https://dev.api.en-trak.com/";
  } else {
    return "https://api.en-trak.com/";
  }
})();

export const COOKIE_PREFIX = (function () {
  if (ENV === "local" || ENV === "dev") {
    return "dev_";
  } else {
    return "";
  }
})();

//dev
export const ONE_SIGNAL_APP_ID = "02f9fe89-0316-424e-9960-91d600240e6b";
//loc
// export const ONE_SIGNAL_APP_ID = "fdc94d05-1ba4-4818-a414-3ebe55aea7c6";

export const CLIENT_ID = "bd971ab3-6cf0-4a1d-9ba2-b815fcd3ae2b";

export const MS_ENDPOINT = "https://login.microsoftonline.com/common/";

// export const MS_OAUTH_URL = (function () {
//   if (ENV === "local" || ENV === "dev") {
//     return "https://sbos-oauth.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&scope=email%20openid%20phone%20profile&redirect_uri=https%3A%2F%2Fdev.api.en-trak.com%2Fauth%2Fcallback";
//   } else {
//     return "https://sbos-oauth.auth.ap-southeast-1.amazoncognito.com/oauth2/authorize?identity_provider=Microsoft&response_type=CODE&scope=email%20openid%20phone%20profile&redirect_uri=https%3A%2F%2Fapi.en-trak.com%2Fauth%2Fcallback";
//   }
// })();

export const LANG = {
  en: "EN_US",
  zh: "ZH_HANT",
  cn: "ZH_HANS"
};

export const DEFAULT_TEMP_RANGE = {
  cool: 25,
  warm: 26
};

export const TRIGGER_BY = {
  invalid: "INVALID",
  user: "USER",
  timetable: "TIMETABLE",
  api: "API",
  scene: "SCENE",
  reservation: "RESERVATION",
  condition: "CONDITION",
  card: "CARD",
  multigang: "MULTIGANG",
  visitor: "VISITOR"
};

export const ACL_ROLE = {
  unknown: "UNKNOWN",
  super: "SUPERUSER",
  landlordUser: "LANDLORD_USER",
  landlordAdmin: "LANDLORD_ADMIN",
  tenantUser: "TENANT_USER",
  tenantAdmin: "TENANT_ADMIN",
  zoneAdmin: "ZONE_ADMIN",
  dashboardAdmin: "DASHBOARD_ADMIN",
  dashboardUser: "DASHBOARD_USER",
  student: "STUDENT"
};

export const LIST_TYPE = {
  room: "ROOM",
  hotdesk: "HOTDESK",
  fixdesk: "FIXDESK"
};

export const ENTITY_TYPE = {
  room: "ROOM",
  workstation: "WORKSTATION",
  zone: "ZONE",
  building: "BUILDING",
  tenant: "TENANT"
};

export const APPLICATION_TYPE = {
  light: "LIGHT",
  aircon: "AIRCON",
  button: "BUTTON",
  gateway: "GATEWAY",
  iaqSensor: "IAQ_SENSOR",
  lightSensor: "LIGHT_SENSOR",
  occupancySensor: "OCCUPANCY_SENSOR",
  switch: "SWITCH",
  door: "DOOR",
  fan: "FAF",
  energyMeter: "ENERGY_METER",
  waterMeter: "WATER_METER",
  thermometer: "THERMOMETER",
  unknown: "UNKNOWN"
};

export const ACTION = {
  checkin: "CHECKIN",
  checkout: "CHECKOUT",
  // extend: "EXTEND",
  normal: "NORMAL",
  // blink: "BLINK",
  warmer: "WARMER",
  cooler: "COOLER"
};

export const TRIGGER = {
  card: "CARD",
  timetable: "TIMETABLE",
  user: "USER",
  api: "API",
  scene: "SCENE",
  button: "BUTTON",
  invalid: "INVALID",
  reservation: "RESERVATION",
  condition: "CONDITION",
  multigang: "MULTIGANG",
  visitor: "VISITOR",
  system: "SYSTEM"
};

export const DEVICE_ACTION = {
  on: "ON",
  off: "OFF",
  blink: "BLINK"
};

export const DEVICE_STATUS = {
  normal: 0,
  selected: 1,
  on: 2,
  off: 3,
  hovered: 4,
  hoverSelected: 5
};

export const MIN_BEFORE_EXTEND = 30;

export const RECENT_NODE_MAX = 6;

export const COLOR = {
  mainLight: "#677A8F",
  mainSubLight: "#677A8F",
  main: "#4D648D",
  blue: "#2D6A9A",
  lightBlue: "#2FA0B0",
  coolerBlue: "#5585FF",
  importantRed: "#FE0F0F",
  red: "#ff5b74",
  warmerRed: "#ff5b74",
  grey: "#c4c4c4",
  mainGrey: "#626262",
  yellow: "#FAA855",
  white: "#FFFFFF",
  green: "#75c639"
};

export const DEFAULT_VALUE = {
  defaultTempStep: 0.5,
  defaultTempMin: 16,
  defaultTempMax: 28,
  co2Step: 10,
  minCo2: 0,
  maxCo2: 4000,
  dimStep: 10,
  minDim: 10,
  maxDim: 100,
  closeDelayStep: 1,
  closeDelayMin: 0,
  closeDelayMax: 30,
  conditionDurationStep: 1,
  conditionDurationMin: 10,
  conditionDurationMax: 20,
  deltaTempStep: 0.5,
  deltaTempMin: 1,
  deltaTempMax: 4
};

export const COMPARE_LOGIC = {
  greater: "GTE",
  lower: "LTE",
  equal: "EQ",
  noLogic: ""
};

export const FAN_SPEED = {
  high: "high",
  medium: "medium",
  low: "low",
  auto: "auto"
};
