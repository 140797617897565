import { useState } from "react";

import ActionPanel from "../../../components/controlPage/actionPanel/ActionPanel";
import ModalLayout from "../../modalLayout/ModalLayout";
import { useModal } from "../../modalContext/ModalContext";
import "./ControlModal.scss";

export default function ControlModal({ isOpen, node, zoneMapRes, userRes, isRm, resolve, reject }) {
  console.log("ControlModal");

  //true: action made
  //false: no action made
  //null: waiting for resolve (since handleToggleNode may open TimerModal)
  const [controlActionMade, setControlActionMade] = useState(false);
  const modal = useModal();

  function handleAfterClose() {
    if (resolve && controlActionMade !== null) {
      resolve(controlActionMade);
    }
  }

  return (
    <ModalLayout isOpen={isOpen} modalContext={modal} modalClassName="controlModal" onAfterClose={handleAfterClose}>
      <div className="mainCard px-35 pt-35">
        <ActionPanel
          nodeRes={{
            data: node,
            error: null,
            called: true,
            loading: false
          }}
          zoneMapRes={zoneMapRes}
          userRes={userRes}
          isRm={isRm}
          isBgDark={true}
          setControlActionMade={setControlActionMade}
          resolve={resolve}
          reject={reject}
        />
      </div>
    </ModalLayout>
  );
}
