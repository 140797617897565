import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import { useUser } from "../userContext/UserContext";
import { Utils } from "../../Utils";
import { ACL_ROLE, TEP_DOMAIN } from "../../Constants";

import "./SideMenu.scss";

export default function SideMenu({ className }) {
  console.log("SideMenu");

  const { t } = useTranslation("common");

  const { userRes } = useUser();
  const cls = className ? `${className} sideMenu` : "sideMenu";

  const pathName = useLocation().pathname.toLowerCase();
  const isControl = pathName.startsWith("/main/head/control");
  // const isConfig = pathName.startsWith("/main/head/location") || pathName.startsWith("/main/head/account") || pathName.startsWith("/main/head/schedule");
  const isLocation = pathName.startsWith("/main/head/location");
  const isSchedule = pathName.startsWith("/main/head/schedule");
  const isCalendar = pathName.startsWith("/main/head/calendar");
  const isAccount = pathName.startsWith("/main/head/account");
  const isScene = pathName.startsWith("/main/head/scene/");
  const isAdminScene = pathName.startsWith("/main/head/sceneSetting");

  const isLog = pathName.startsWith("/main/head/log");
  const isHeatmap = pathName.startsWith("/main/head/heatmap");
  const isCompany = pathName.startsWith("/main/head/company");
  const isDashboard = pathName.startsWith("/main/head/dashboard");
  const isInsights = pathName.startsWith("/main/head/insights");

  const isAdmin = isLocation || isSchedule || isCalendar || isAccount || isAdminScene || isLog || isHeatmap || isCompany;

  const [showAdminMenu, setShowAdminMenu] = useState(false);

  function closeAdminMenu() {
    setShowAdminMenu(false);
  }

  function goTep(path) {
    window.location.href = TEP_DOMAIN + path;
  }

  {
    /*TODOricky when the page already, replace all <a> to <Link>, remove onClick, goTep*/
  }
  return (
    <>
      <div className={cls}>
        <div className="overflow-y-auto flex-fill-remain noScrollbar h-100">
          <div className="sideMenuContent light smaller py-45 text-center">
            {/* if user only have STUDENT or DASHBOARD_ADMIN, they cannot see this page */}
            {userRes.data?.tenant?.enableTep && Utils.hasMinAclRole(userRes.data, ACL_ROLE.tenantUser) && (
              <div className={"mb-3" + (isControl ? " selected" : "")}>
                <Link className="py-25" to={"/main/head/control"} onClick={closeAdminMenu}>
                  <div className="icon control pt-25 pb-3 mb-1"></div>
                  <span>{t("mainPage.title")}</span>
                </Link>
              </div>
            )}
            {/* if user only have STUDENT or DASHBOARD_ADMIN, they cannot see this page */}
            {Utils.hasMinAclRole(userRes.data, ACL_ROLE.tenantUser) && (
              <div className={"mb-3" + (isDashboard ? " selected" : "")}>
                <Link className="py-25" to={"/main/head/dashboard"} onClick={closeAdminMenu}>
                  <div className="icon dashboard pt-25 pb-3 mb-1"></div>
                  <span>{t("dashboardPage.title")}</span>
                </Link>
              </div>
            )}
            {(Utils.isAclRole(userRes.data, ACL_ROLE.dashboardAdmin) || Utils.isAclRole(userRes.data, ACL_ROLE.student)) && (
              <div className={"mb-3" + (isInsights ? " selected" : "")}>
                <Link className="py-25" to={"/main/head/insights"} onClick={closeAdminMenu}>
                  <div className="icon insights pt-25 pb-3 mb-1"></div>
                  <span>{t("insightsPage.title")}</span>
                </Link>
              </div>
            )}
            {Utils.hasMinAclRole(userRes.data, ACL_ROLE.zoneAdmin) && (
              <div className={"mb-3" + (isAdmin ? " selected" : "")}>
                <a className="py-25" onClick={() => setShowAdminMenu(!showAdminMenu)}>
                  <div className="icon admin pt-25 pb-3 mb-1"></div>
                  <span>{t("lbl.admin")}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
      {showAdminMenu && (
        <div className={cls}>
          <div className="overflow-y-auto flex-fill-remain noScrollbar h-100">
            <div className="subSideMenuContent light smaller py-45 text-center">
              {userRes.data?.tenant?.enableTep && Utils.hasMinAclRole(userRes.data, ACL_ROLE.zoneAdmin) && (
                <>
                  <div className={"mb-3" + (isLocation ? " selected" : "")}>
                    <Link className="py-25" to={"/main/head/location"} onClick={closeAdminMenu}>
                      <div className="icon location main pt-25 pb-2 mb-1"></div>
                      <span>{t("locationPage.title")}</span>
                    </Link>
                  </div>
                  <div className={"mb-3" + (isAdminScene ? " selected" : "")} onClick={() => goTep("scene")}>
                    <a className="py-25" to={"/main/head/scene"} onClick={closeAdminMenu}>
                      <div className="icon scene main pt-25 pb-2 mb-1"></div>
                      <span>{t("scenePage.title")}</span>
                    </a>
                  </div>
                  <div className={"mb-3" + (isSchedule ? " selected" : "")} onClick={() => goTep("schedule")}>
                    <a className="py-25" to={"/main/head/schedule"} onClick={closeAdminMenu}>
                      <div className="icon schedule main pt-25 pb-2 mb-1"></div>
                      <span>{t("schedulePage.title")}</span>
                    </a>
                  </div>
                </>
              )}
              {userRes.data?.tenant?.enableTep && Utils.hasMinAclRole(userRes.data, ACL_ROLE.tenantAdmin) && (
                <div className={"mb-3" + (isCalendar ? " selected" : "")} onClick={() => goTep("calendar")}>
                  <a className="py-25" to={"/main/head/calendar"} onClick={closeAdminMenu}>
                    <div className="icon calendar main pt-25 pb-2 mb-1"></div>
                    <span>{t("calendarPage.title")}</span>
                  </a>
                </div>
              )}
              {Utils.hasMinAclRole(userRes.data, ACL_ROLE.zoneAdmin) && (
                <div className={"mb-3" + (isAccount ? " selected" : "")}>
                  <Link className="py-25" to={"/main/head/account"} onClick={closeAdminMenu}>
                    <div className="icon account main pt-25 pb-2 mb-1"></div>
                    <span>{t("accountPage.title")}</span>
                  </Link>
                </div>
              )}
              {userRes.data?.tenant?.enableTep && Utils.hasMinAclRole(userRes.data, ACL_ROLE.zoneAdmin) && (
                <>
                  <div className={"mb-3" + (isLog ? " selected" : "")} onClick={() => goTep("log")}>
                    <a className="py-25" to={"/main/head/log"} onClick={closeAdminMenu}>
                      <div className="icon log main pt-25 pb-2 mb-1"></div>
                      <span>{t("logPage.title")}</span>
                    </a>
                  </div>
                  <div className={"mb-3" + (isHeatmap ? " selected" : "")} onClick={() => goTep("selectHeatmap")}>
                    <a className="py-25" to={"/main/head/heatmap"} onClick={closeAdminMenu}>
                      <div className="icon heatmap main pt-25 pb-2 mb-1"></div>
                      <span>{t("heatmapPage.title")}</span>
                    </a>
                  </div>
                </>
              )}
              {Utils.hasMinAclRole(userRes.data, ACL_ROLE.tenantAdmin) && (
                <>
                  <div className={"mb-3" + (isCompany ? " selected" : "")} onClick={() => goTep("company")}>
                    <a className="py-25" to={"/main/head/company"} onClick={closeAdminMenu}>
                      <div className="icon settings main pt-25 pb-2 mb-1"></div>
                      <span>{t("companyPage.title")}</span>
                    </a>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
