import { memo } from "react";

import { DEVICE_STATUS } from "../../Constants";

//onClick(device)
export default memo(function Device({ device, status, disabled, onClick }) {
  console.log("Device", device.id);

  let typeClass = device.applicationType;

  if (DEVICE_STATUS.selected === status) {
    typeClass += " icon marker mainBg";
  } else if (DEVICE_STATUS.on === status) {
    typeClass += " icon marker yellowBg";
  } else if (DEVICE_STATUS.off === status) {
    typeClass += " icon marker redBg";
  } else if (DEVICE_STATUS.hovered === status) {
    typeClass += " icon marker main enlarge";
  } else if (DEVICE_STATUS.hoverSelected === status) {
    typeClass += " icon marker mainBg enlarge";
  } else {
    typeClass += " icon marker main";
  }
  if (disabled) {
    typeClass += " disabled";
  }

  let loc = {};
  if (device.location) {
    loc.left = device.location.x + "px";
    loc.top = device.location.y + "px";
  }

  if (device) {
    if (disabled || !onClick) {
      return <div className={typeClass} style={loc}></div>;
    } else {
      return <div className={typeClass} style={loc} onClick={() => onClick(device)}></div>;
    }
  } else {
    return "";
  }
});
