import OneSignal from "react-onesignal";

import { ONE_SIGNAL_APP_ID } from "./Constants";

let oneSignal = null;
export function initOneSignal() {
  if (oneSignal == null) {
    oneSignal = OneSignal.init({ appId: ONE_SIGNAL_APP_ID });
  }

  return oneSignal;
}

export async function setExternalUserId(externalUserId, hash4OneSignal) {
  if (oneSignal) {
    await oneSignal;
    let localExternalId = await OneSignal.getExternalUserId();
    if (!localExternalId) {
      console.log("Set ExternalUserId", externalUserId);
      OneSignal.setExternalUserId(externalUserId, hash4OneSignal);
    } else if (localExternalId !== externalUserId) {
      console.log("update ExternalUserId", externalUserId);
      OneSignal.removeExternalUserId();
      OneSignal.setExternalUserId(externalUserId, hash4OneSignal);
    } else {
      console.log("ExternalUserId already set");
    }
  } else {
    console.error("one signal is not initialized");
  }
}

export async function removeExternalUserId(hash4OneSignal) {
  if (oneSignal) {
    console.log("Remove ExternalUserId");
    await oneSignal;
    OneSignal.setExternalUserId("", hash4OneSignal);
    // OneSignal.removeExternalUserId();
  } else {
    console.error("one signal is not initialized");
  }
}

export async function sendTags(tags) {
  if (oneSignal) {
    console.log("sendTags");
    await oneSignal;
    return await OneSignal.sendTags(tags);
  } else {
    console.error("one signal is not initialized");
  }
}

export async function showSlidedownPrompt(callback) {
  if (oneSignal) {
    await oneSignal;
    OneSignal.showSlidedownPrompt().then(typeof callback === "function" ? callback : undefined);
  } else {
    console.error("one signal is not initialized");
  }
}

export async function print() {
  if (oneSignal) {
    try {
      await oneSignal;
      let [isSubscribed, userId, externalUserId, notificationPermission] = await Promise.all([
        OneSignal.isPushNotificationsEnabled(),
        OneSignal.getUserId(),
        OneSignal.getExternalUserId(),
        OneSignal.getNotificationPermission()
      ]);
      console.log("isPushNotificationsEnabled:", isSubscribed);
      console.log("getUserId:", userId);
      console.log("getExternalUserId:", externalUserId);
      console.log("getNotificationPermission:", notificationPermission);
    } catch (e) {
      console.error("Issue determining whether push is enabled:", e);
    }
  } else {
    console.error("one signal is not initialized");
  }
}
