import { createContext, useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";

import MsgModal from "../msgModal/MsgModal";
import ConfirmMsgModal from "../msgModal/ConfirmMsgModal";

export const SimpleModalContext = createContext();

export function SimpleModalProvider({ children }) {
  console.log("SimpleModalProvider");

  const { t } = useTranslation("common");
  const [modalData, setModalData] = useState({});
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    clearTimeout(timer);
    if (modalData.autoClose && modalData.isOpen) {
      setTimer(setTimeout(() => data.close(), 3000));
    }
  }, [modalData]);

  //TODOricky move all msg type modal to here
  const data = useMemo(() => {
    return {
      // open: setModalData,
      close: () => setModalData({}),
      deleteMsg: (msg, onDelete, noAutoDisable) =>
        setModalData({
          id: "ConfirmMsgModal",
          isOpen: true,
          isSimple: true,
          msg,
          btnClass: "red",
          btnText: t("lbl.delete"),
          iconClass: "warning",
          noAutoDisable,
          callback: onDelete
        }),
      confirmMsg: (msg, onConfirm, noAutoDisable) =>
        setModalData({
          id: "ConfirmMsgModal",
          isOpen: true,
          isSimple: true,
          msg,
          btnClass: "yellow",
          btnText: t("lbl.confirm"),
          iconClass: "warning",
          noAutoDisable,
          callback: onConfirm
        }),
      generalErrMsg: () => setModalData({ id: "MsgModal", isOpen: true, isSimple: true, msg: t("err.generalFailLong"), iconClass: "warning", showClose: true }),
      errMsg: (msg, info, onAfterClose) => setModalData({ id: "MsgModal", isOpen: true, isSimple: true, msg, info, iconClass: "warning", showClose: true, onAfterClose })
    };
  }, []);

  return (
    <SimpleModalContext.Provider value={data}>
      {children}
      {modalData.id === "ConfirmMsgModal" && <ConfirmMsgModal {...modalData} />}
      {modalData.id === "MsgModal" && <MsgModal {...modalData} />}
    </SimpleModalContext.Provider>
  );
}

export function useSimpleModal() {
  return useContext(SimpleModalContext);
}
