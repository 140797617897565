import "./Spinner.scss";

export default function Spinner({ height, size, isCenter, isThin }) {
  const s = size ? size : "2rem";
  if (isCenter) {
    const h = height ? height : s;
    const pad = `calc((${h} - ${s}) / 2)`;
    const containerStyle = height ? { paddingTop: pad, paddingBottom: pad } : {};
    return (
      <div className="spinnerContainer" style={containerStyle}>
        <div className={"spinner-border" + (isThin ? " thin" : "")} role="status" style={{ height: s, width: s }}>
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    return (
      <div className={"spinner-border" + (isThin ? " thin" : "")} role="status" style={{ height: s, width: s }}>
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
}
