import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";

import { logSignInMutation } from "../../../Gql";
import { useApolloClient } from "../../../components/apolloClientContext/ApolloClientContext";
import { CLIENT_ID, MS_ENDPOINT } from "../../../Constants";
import { Utils } from "../../../Utils";
import "./LoginPage.scss";

export default function LoginPage() {
  console.log("LoginPage");

  const navigate = useNavigate();
  const { t } = useTranslation("common");
  const apolloClientContext = useApolloClient();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [pwdType, setPwdType] = useState("password");
  const [signingIn, setSigningIn] = useState(false);
  const [errMessage, setErrMessage] = useState(null);

  const [logSignIn] = useMutation(logSignInMutation);

  const disabledSignIn = !email.trim() || !password.trim() || signingIn;

  function togglePwd() {
    setPwdType(pwdType === "password" ? "text" : "password");
  }

  function loginByEmail(event) {
    event.preventDefault();
    if (disabledSignIn) {
      return;
    }
    setSigningIn(true);
    Utils.formPost("auth/signin", { password, email, tokens: 1 })
      .then(Utils.parseJson)
      .then(res => {
        setSigningIn(false);
        if (res.errno) {
          console.log("signin error:", res.errno, res.errmsg);
          if (res.errno == 504 || res.errno == 406) {
            setErrMessage(t("err.pwdTooManyAttempted"));
          } else if (res.errno == 402) {
            setErrMessage(t("err.checkEmail"));
          } else if (res.errno == 505) {
            setErrMessage(t("err.invalidLogin"));
          } else if (res.errno == 502) {
            navigate(`/createPwd/${encodeURIComponent(email)}/${res.extra.session}/${res.extra.userpool}`);
          } else {
            setErrMessage(t("err.contactAdmin"));
          }
        } else {
          console.log("cognito login success");
          apolloClientContext.updateClient("cognitoToken", res.extra.id_token);
          logSignIn();
          Utils.saveSignInInfo("cognitoToken", res.extra.id_token, res.extra.refresh_token, res.extra.access_token);
          navigate("/main/head/home");
        }
      })
      .catch(err => {
        setSigningIn(false);
        console.error("login err", err);
        setErrMessage(t("err.contactAdmin"));
      });
  }

  function loginByMS(noPrompt) {
    setSigningIn(true);
    Utils.msSignIn();
  }

  return (
    <div className="loginPage h-100 d-flex">
      <div className="w-50 p-45 d-none d-lg-block">
        <div className="icon logo d-inline-block px-6 py-4"></div>
      </div>
      <div className="loginCard p-5 d-flex flex-column m-auto">
        {errMessage ? (
          <>
            <div>
              <div className="p-45 fail icon"></div>
            </div>
            <div className="whiteFont biggest bold my-4 text-center">{t("err.generalLoginFail")}</div>
            <div className="container">
              <div className="greyFont text-center">{errMessage}</div>
            </div>
            <div className="container text-center">
              <button className="btn plain yellow mt-4 d-inline-flex align-items-center" onClick={() => setErrMessage(null)}>
                <span className="d-inline-block backArrow yellow icon p-2 me-2"></span>
                <span>{t("login.backToSignIn")}</span>
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="whiteFont biggest bold">{t("login.signIn")}</div>
            <div className="d-flex align-items-center mt-25 mb-5">
              <span className="greyFont">{t("login.createAccDesc")}</span>
              <button className="px-1 py-0 btn plain yellow" onClick={() => navigate("/new")}>
                {t("login.createAcc")}
              </button>
            </div>
            <form onSubmit={loginByEmail}>
              <div className="greyFont">{t("lbl.email")}</div>
              <input className="inputBox w-100 py-2 mt-1 mb-3" placeholder={t("lbl.email")} value={email} onChange={e => setEmail(e.target.value)}></input>
              <div className="greyFont">{t("lbl.pwd")}</div>
              <div className="position-relative mt-1 mb-3">
                <input
                  className="inputBox w-100 pe-5 py-2"
                  autoComplete="off"
                  placeholder={t("lbl.pwd")}
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  type={pwdType}
                ></input>
                <button type="button" className={"btn plain round eye icon p-2" + (pwdType !== "password" ? "" : " on")} onClick={togglePwd}></button>
              </div>
              <button type="button" className="btn plain yellow py-0 px-1 ms--1 mb-3" onClick={() => navigate("/forgot")}>
                {t("login.forgot") + "?"}
              </button>
              <button className="btn yellow py-2 w-100" type="submit" disabled={disabledSignIn}>
                {t("login.signIn")}
              </button>
            </form>
            <div className="row align-items-center mt-4 mb-35">
              <div className="col underline ms-45"></div>
              <div className="col-auto greyFont">{t("login.or")}</div>
              <div className="col underline me-45"></div>
            </div>
            <div className="text-center">
              <button className="btn plain white small" disabled={signingIn} onClick={loginByMS}>
                <span className="msLogo icon p-3 me-2"></span>
                {t("login.msSignIn")}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
