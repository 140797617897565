import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useApolloClient } from "../../../components/apolloClientContext/ApolloClientContext";
import { Utils } from "../../../Utils";
import { ENV } from "../../../Constants";

//when 401, will come to this page
export default function Handle401Page() {
  const [done, setDone] = useState(null);
  const navigate = useNavigate();
  const p = useParams();
  const [searchParams] = useSearchParams();
  const apolloClientContext = useApolloClient();
  console.log("RedirectPage");

  function goLogin() {
    Utils.clearSignInInfo();
    navigate("/login", { replace: true });
  }

  useEffect(() => {
    let idToken, refreshToken, method;
    if (ENV === "local") {
      idToken = Utils.storageGet("et_it");
      refreshToken = Utils.storageGet("et_rt");
      method = Utils.storageGet("et_method");
    } else {
      idToken = Utils.getCookie("et_it");
      refreshToken = Utils.getCookie("et_rt");
      method = Utils.getCookie("et_method");
    }

    if (method) {
      const refreshSuccess = res => {
        Utils.saveSignInInfo(method, res.id_token, res.refresh_token, method === "cognitoToken" ? res.access_token : null);
        apolloClientContext.updateClient(method, res.id_token);
        console.log("token refreshed successfully");
        setDone(true);
      };
      if (!refreshToken) {
        console.log("refresh token not found...");
        goLogin();
      } else if (method === "microsoft") {
        console.log("try to refresh ms token...");
        Utils.refreshMsToken(refreshToken, refreshSuccess, () => {
          console.log("unabled to refresh ms, try slient signIn");
          Utils.msSignIn(true);
        });
      } else if (method === "cognitoToken") {
        console.log("try to refresh cognito token...");
        Utils.formPost("auth/token", { refresh_token: refreshToken })
          .then(Utils.parseJson)
          .then(refreshSuccess, () => {
            console.log("unabled to refresh cognito, go login");
            goLogin();
          });
      } else if (method === "normal") {
        console.log("try to refresh... normal token...");
        Utils.formPost("auth/ex/refresh", { refresh_token: refreshToken })
          .then(Utils.parseJson)
          .then(refreshSuccess, () => {
            console.log("unabled to refresh, go error display");
            Utils.clearSignInInfo();
            navigate("/tokenError", { replace: true });
          });
      } else {
        console.log("method not supported, go sign in...");
        goLogin();
      }
    } else {
      console.log("previous signIn method not found...");
      goLogin();
    }
  }, []);

  useEffect(() => {
    if (done) {
      let path = decodeURIComponent(p.path);
      let entries = searchParams.entries();
      let queryStr = [];
      while (true) {
        let e = entries.next();
        if (e.done) {
          break;
        } else {
          queryStr.push(e.value[0] + "=" + e.value[1]);
        }
      }
      console.log("redirect to -->", path, queryStr);
      if (queryStr.length) {
        navigate({
          pathname: path,
          search: "?" + queryStr.join("&")
        });
      } else {
        navigate(path);
      }
    }
  }, [done]);

  return <div></div>;
}
