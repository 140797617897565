import { useTranslation } from "react-i18next";

import { Utils } from "../../../Utils";
import "./NodeRow.scss";

export default function NodeRow({ node, listType, onClick }) {
  console.log("NodeRow", node.owners);

  const { t } = useTranslation("common");

  const content = (
    <>
      <div className={`col-auto icon p-25 main ${listType || "FLOOR"}`}></div>
      <div className="col-auto small oneLine">{node.name}</div>
      {node.owners?.length > 0 && <div className="col smaller oneLine greyestFont">{t("mainPage.occupiedBy", { name: Utils.getUserDisplayName(t, node.owners[0]) })}</div>}
    </>
  );

  return onClick ? (
    <button className="btn plain text-left nodeRow row align-items-center mx-0 py-2 px-45" onClick={() => onClick(node)}>
      {content}
    </button>
  ) : (
    <div className="nodeRow row align-items-center mx-0 py-2 px-45">{content}</div>
  );
}
