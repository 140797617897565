import { useState } from "react";

import "./Slider.scss";

//onChange(val)
export default function Slider({ value, step, max, min, disabled, onChange, className }) {
  const [localValue, setLocalValue] = useState(10);
  const [changeTimerVal, setChangeTimerVal] = useState(null);
  const [inputTimerVal, setInputTimerVal] = useState(null);
  const [showUserValue, setShowUserValue] = useState(false);

  console.log("Slider", value, localValue);

  function handleOnChange(e) {
    let dimLv = e.target.value;
    setLocalValue(dimLv);
    clearTimeout(changeTimerVal);
    const timer = setTimeout(() => {
      onChange(parseFloat(dimLv));
    }, 500);
    setChangeTimerVal(timer);
  }

  function handleOnInput() {
    setShowUserValue(true);
    clearTimeout(inputTimerVal);
    const timer = setTimeout(() => {
      setShowUserValue(false);
    }, 1000);
    setInputTimerVal(timer);
  }

  return (
    <input
      type="range"
      value={showUserValue ? localValue : value}
      disabled={disabled}
      step={step}
      max={max}
      min={min}
      className={className ? className + " slider" : "slider"}
      onChange={handleOnChange}
      onInput={handleOnInput}
    />
  );
}
