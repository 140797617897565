import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useMutationErrHandler, useToggleNode, useChangeNodeCond, getData } from "../../../Gql";
import { Utils } from "../../../Utils";
import { ACTION, DEFAULT_VALUE } from "../../../Constants";
import { showSlidedownPrompt } from "../../../OneSignal";
import { useModal } from "../../modalContext/ModalContext";
import SwitchBtn from "../../switchBtn/SwitchBtn";
import Slider from "../../slider/Slider";
import Spinner from "../../spinner/Spinner";

//only pass in setControlActionMade, resolve, reject if need to know controlActionMade
export default function ActionPanel({ nodeRes, userRes, zoneMapRes, isRm, isCur, hideTitle, isBgDark, setControlActionMade, resolve, reject }) {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  if ((isRm && isCur) || !nodeRes) {
    console.error("Invalid params", nodeRes, isRm, isCur);
  }

  const toggleMethods = useToggleNode();
  const [changeCondNode, changeCondNodeData] = useChangeNodeCond(isRm);
  const errHandler = useMutationErrHandler();

  const zone = zoneMapRes.data && nodeRes.data ? zoneMapRes.data[nodeRes.data.zoneId] : null;
  const modal = useModal();

  console.log("ActionPanel", nodeRes, zone, zoneMapRes.data, nodeRes.data);
  console.log("ActionPanel(mutation)", changeCondNodeData);

  useEffect(() => {
    if (isCur && nodeRes.data) {
      setTimeout(showSlidedownPrompt, 8000);
    }
  }, [isCur, nodeRes.data]);

  function temperatureModal(action) {
    const minute = zone ? zone.defaultConditionDuration : "";
    modal.temperatureMsg(action === ACTION.warmer);
  }

  function temperatureLockModal(minute) {
    modal.temperatureLockMsg(minute);
  }

  function handleToggleNode(e, node) {
    if (setControlActionMade) {
      //tell container modal to wait for handleToggleNode resolve
      setControlActionMade(null);
    }
    //must setTimeout for the container read controlActionMade correctly
    setTimeout(() => {
      Utils.handleToggleNode(e, node, userRes, modal, toggleMethods, errHandler).then(actionMade => {
        if (resolve) {
          resolve(actionMade);
        }
      });
    });
  }

  function handleExtend() {
    if (setControlActionMade) {
      //tell container modal to wait for TimerModal resolve
      setControlActionMade(null);
    }
    //must setTimeout for the container read controlActionMade correctly
    setTimeout(() => {
      modal.asyncOpen({ id: "TimerModal", node: nodeRes.data, isRm, isExtend: true, userRes }).then(actionMade => {
        if (resolve) {
          resolve(actionMade);
        }
      });
    });
  }

  function handleTemperatureChange(action) {
    if (action === ACTION.cooler || action === ACTION.warmer) {
      changeCondNode({
        variables: { id: nodeRes.data.id, condition: { comfortState: action } }
      }).then(res => {
        if (setControlActionMade) {
          setControlActionMade(true);
        }
        const r = getData(res);
        if (r?.actionTimeLeft.action === ACTION.cooler || r?.actionTimeLeft.action === ACTION.warmer) {
          temperatureLockModal(Utils.getMinuteDiff(r.actionTimeLeft.timeLeft));
        } else {
          temperatureModal(action);
        }
      }, errHandler);
    }
  }
  function handleDimming(val) {
    changeCondNode({
      variables: { id: nodeRes.data.id, condition: { dimmingLevel: val } }
    }).then(() => {
      if (setControlActionMade) {
        setControlActionMade(true);
      }
      modal.turnOnMsg();
    }, errHandler);
  }

  function handleScene() {
    //close the controlOthersModal, will trigger resolve there
    modal.close();
    navigate(`/main/head/scene/${isRm}/${nodeRes.data.id}`);
  }

  if (nodeRes.loading || zoneMapRes.loading || nodeRes.error || zoneMapRes.error) {
    return (
      <div className="actionPanel">
        <Spinner isCenter={true} height={"4rem"} />
      </div>
    );
  } else if (nodeRes.data) {
    const isOn = Utils.isCheckedIn(nodeRes.data);
    const showScene = Utils.needShowScene(nodeRes.data);
    const { minute, needShowExtend } = Utils.getExtendInfo(nodeRes.data);
    const statusMsg = Utils.getNodeStatusMsg(t, nodeRes.data);
    const rowCls = "row align-items-center pb-3 pb-lg-4";
    return (
      <div className={isBgDark ? "whiteFont actionPanel" : "actionPanel"}>
        {!isCur && !hideTitle && (
          <div className="pb-3 pb-lg-4">
            <div>{t("lbl.youSelected")}</div>
            <div className="bold oneLine">{nodeRes.data.name}</div>
          </div>
        )}
        {zone &&
          !zone.alwaysOn && ( // on/off status
            <div className={rowCls}>
              <div className="col pe-1">
                <div>{t("lbl.currentStatus")}</div>
                <div className="bold">{statusMsg}</div>
              </div>
              <div className="col-auto">
                <SwitchBtn className="large" checked={isOn} onChange={handleToggleNode} data={nodeRes.data} />
              </div>
            </div>
          )}
        {needShowExtend && ( // extend
          <div className={rowCls}>
            <div className="col pe-1">
              <div className="bold redFont">{t("lbl.willBeOffIn", { minute: minute == 0 ? 1 : minute })}</div>
            </div>
            <div className="col-auto">
              <button className="btn red round w8 bold" onClick={handleExtend}>
                {t("lbl.extend")}
              </button>
            </div>
          </div>
        )}
        {Utils.needShowTemperature(nodeRes.data) && ( // cooler/warmer
          <div className={rowCls}>
            {userRes.data?.tenant.roomTemperatureVisibility ? (
              <div className="col pe-1 oneLine">
                <div className="oneLine">{t("lbl.currentTemperature")}</div>
                <div className="bold">{nodeRes.data.roomTemperature}°C</div>
              </div>
            ) : (
              <>
                <div className="col-auto pe-1">
                  <div className={"icon temperature p-3" + (isBgDark ? "" : " main")}></div>
                </div>
                <div className="col px-0"></div>
              </>
            )}
            <div className="col-auto px-0">
              <button className="btn round coolerBlue w7 bold" onClick={() => handleTemperatureChange(ACTION.cooler)} disabled={changeCondNodeData.loading}>
                {t("lbl.cooler")}
              </button>
            </div>
            <div className="col-auto">
              <button className="btn round warmerRed w7 bold" onClick={() => handleTemperatureChange(ACTION.warmer)} disabled={changeCondNodeData.loading}>
                {t("lbl.warmer")}
              </button>
            </div>
          </div>
        )}
        {Utils.needShowDimming(nodeRes.data) ? ( // dimming
          <div className={rowCls}>
            <div className="col-auto">
              <div className={"icon lightOff p-3 " + (isBgDark ? "white" : "main")}></div>
            </div>
            <div className="col px-1">
              <Slider
                className="withBorder large"
                value={nodeRes.data.dimmingLevel}
                step={DEFAULT_VALUE.dimStep}
                max={DEFAULT_VALUE.maxDim}
                min={DEFAULT_VALUE.minDim}
                disabled={changeCondNodeData.loading}
                onChange={handleDimming}
              />
            </div>
            <div className="col-auto">
              <div className={"icon lightOn p-3 " + (isBgDark ? "white" : "main")}></div>
            </div>
          </div>
        ) : null}
        {showScene && (
          <div className={rowCls}>
            <div className="col-auto">
              <button onClick={handleScene} className="btn plain yellow bold p-0 d-flex align-items-center">
                {t("lbl.selectScene")}
              </button>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    //no ws assigned
    return "";
  }
}
