import Select from "react-dropdown-select";
import { Checkbox } from "antd";

import "./Dropdown.scss";

//value or valueObj is for displaying the selected item, if both are provided, only value will be used
export default function Dropdown({ value, valueObj, options, isMultiSelect, disabled, placeholder, itemRenderer, className, handleColor, onChange, onDelete }) {
  console.log("Dropdown");

  let val = [];
  function setValById(v) {
    let item = options.find(o => o.id === v);
    if (item) {
      val.push(item);
    }
  }
  if (value !== undefined && options) {
    if (isMultiSelect) {
      value.forEach(setValById);
    } else {
      setValById(value);
    }
  } else if (valueObj) {
    if (isMultiSelect) {
      val = valueObj;
    } else {
      val.push(valueObj);
    }
  }

  function handleChange(values) {
    if (onChange) {
      if (isMultiSelect) {
        onChange(values);
      } else {
        onChange(values && values.length ? values[0] : null);
      }
    }
  }

  const contentRenderer = isMultiSelect
    ? ({ state }) => {
        return <span>{state.values.map(v => v.name).join(", ")}</span>;
      }
    : null;

  const dropdownElm = (
    <Select
      className={(className ? className + " dropdown" : "dropdown") + (isMultiSelect ? " multi" : "")}
      valueField="id"
      labelField="name"
      values={val}
      disabled={disabled}
      options={options}
      searchable={false}
      multi={isMultiSelect}
      itemRenderer={
        itemRenderer ||
        (isMultiSelect &&
          function ({ item, state, methods }) {
            // state.values is the values before onClick trigger
            return (
              <Checkbox
                disabled={item.disabled}
                checked={methods.isSelected(item)}
                onClick={() => {
                  if (methods.isSelected(item)) {
                    // just deselect it
                  } else if (item.unique) {
                    // clear all then select it
                    methods.clearAll();
                  } else if (state.values[0]?.unique) {
                    // if already have unique item selected, clearAll then select it
                    methods.clearAll();
                  } else {
                    // its a normal item, just select it
                  }
                  // use setTimeout to prevent it overwriting clearAll()
                  setTimeout(() => methods.addItem(item), 10);
                }}
              >
                {item.name}
              </Checkbox>
            );
          })
      }
      contentRenderer={contentRenderer}
      placeholder={placeholder ? placeholder : ""}
      onChange={handleChange}
      dropdownHandleRenderer={() => <span className={handleColor ? `${handleColor} p-2 icon downArrow` : ""}></span>}
    />
  );

  if (onDelete) {
    return (
      <div className="dropdownContainer">
        {dropdownElm}
        <div className="delBtnContainer d-flex align-items-center">
          <button className="btn icon remove p-2" disabled={disabled} onClick={onDelete}></button>
        </div>
      </div>
    );
  } else {
    return dropdownElm;
  }
}
