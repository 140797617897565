import { createContext, useContext, useState, useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { useNavigate } from "react-router-dom";

import { createApolloClient } from "../../Gql";
import { Utils } from "../../Utils";
import { ENV } from "../../Constants";

const ApolloClientContext = createContext();

export function ApolloClientProvider({ children }) {
  console.log("ApolloClientProvider");

  //dummy client, not supppose to use but ApolloProvider dont accept null
  const [client, setClient] = useState(createApolloClient());
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setInterval(() => {
      if (window.location.pathname.startsWith("/main/")) {
        let refreshToken = ENV === "local" ? Utils.storageGet("et_rt") : Utils.getCookie("et_rt");
        let method = ENV === "local" ? Utils.storageGet("et_method") : Utils.getCookie("et_method");
        if (refreshToken) {
          const refreshSuccess = res => {
            Utils.saveSignInInfo(method, res.id_token, res.refresh_token, method === "cognitoToken" ? res.access_token : null);
            updateClient(method, res.id_token);
            console.log("refresh token success");
          };
          if (method === "microsoft") {
            console.log("try refresh ms token...");
            Utils.refreshMsToken(refreshToken, refreshSuccess, () => {
              console.log("unabled to refresh, try slient signIn...");
              Utils.msSignIn(true);
            });
          } else if (method === "cognitoToken") {
            console.log("try refresh cognito token...");
            Utils.formPost("auth/token", { refresh_token: refreshToken })
              .then(Utils.parseJson)
              .then(refreshSuccess, () => {
                console.log("unabled to refresh, go login");
                Utils.clearSignInInfo();
                navigate("/login", { replace: true });
              });
          } else if (method === "normal") {
            console.log("try refresh... normal token...");
            Utils.formPost("auth/ex/refresh", { refresh_token: refreshToken })
              .then(Utils.parseJson)
              .then(refreshSuccess, () => {
                console.log("unabled to refresh, go login");
                Utils.clearSignInInfo();
                navigate("/tokenError", { replace: true });
              });
          }
        }
      }
    }, 60000 * 58);
    return () => {
      clearInterval(timer);
    };
  }, []);

  function updateClient(method, idToken) {
    setClient(createApolloClient(method, idToken));
  }

  return (
    <ApolloClientContext.Provider value={{ updateClient }}>
      <ApolloProvider client={client}>{children}</ApolloProvider>
    </ApolloClientContext.Provider>
  );
}

export function useApolloClient() {
  return useContext(ApolloClientContext);
}
