import Modal from "react-modal";

import "./ModalLayout.scss";

//share use
export default function ModalLayout({ isOpen, modalClassName, onAfterClose, onCancel, modalContext, modalContentClassName, children }) {
  console.log("ModalLayout");

  const cls = modalClassName ? modalClassName + " modalLayout" : "modalLayout";
  const mcCls = modalContentClassName ? modalContentClassName + " modalContent d-flex flex-column" : "modalContent d-flex flex-column";

  function back() {
    modalContext.close();
    if (typeof onCancel === "function") {
      onCancel();
    }
  }

  return (
    <Modal isOpen={isOpen} portalClassName={cls} onAfterClose={onAfterClose}>
      <div className="h-100 d-flex flex-column">
        <div className="flex-fill-remain" onClick={back}></div>
        <div className="modalMidRow">
          {/* for fixed height, set height for modalMidRow */}
          <div className="d-flex h-100">
            <div className="flex-fill-remain" onClick={back}></div>
            <div className={mcCls}>{children}</div>
            <div className="flex-fill-remain" onClick={back}></div>
          </div>
        </div>
        <div className="flex-fill-remain" onClick={back}></div>
      </div>
    </Modal>
  );
}
