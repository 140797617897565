import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { useSimpleModal } from "../modalContext/SimpleModalContext";
import { useModal } from "../../components/modalContext/ModalContext";
import PwdChecker from "../pwdChecker/PwdChecker";
import { handle401 } from "../../Gql";
import { removeExternalUserId } from "../../OneSignal";
import { Utils } from "../../Utils";
import { ENV } from "../../Constants";
import "./index.scss";

export default function ChangePasswordModal({ isOpen, userRes }) {
  const { t } = useTranslation("common");

  const modal = useModal();
  const simpleModal = useSimpleModal();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [isValidFormat, setIsValidFormat] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const handleOk = () => {
    modal.close();
  };

  const [form] = Form.useForm();
  const onValuesChange = (changedValues, allValues) => {
    setPassword(!allValues.pwd ? "" : allValues.pwd);
    setOldPassword(!allValues.oldPwd ? "" : allValues.oldPwd);
    setCanSubmit(allValues.oldPwd && allValues.pwd === allValues.password);
  };
  const onFinish = values => {
    setLoading(true);
    const accessToken = ENV === "local" ? Utils.storageGet("et_at") : Utils.getCookie("et_at");
    Utils.formPost("auth/changepwd", { old_password: oldPassword, new_password: password, access_token: accessToken }, true)
      .then(Utils.parseJson)
      .then(r => {
        if (r.errno) {
          if (r.errno == 504) {
            simpleModal.errMsg(t("err.invalidLogin"));
          } else if (r.errno == 401 || r.errno == 404) {
            console.log("invalid token", r.errno);
            simpleModal.errMsg(t("err.generalFailLong"));
            handle401(navigate, location, searchParams);
          } else {
            simpleModal.errMsg(t("err.generalFailLong"));
          }
          console.log("change pwd error", r.errno, r.errmsg);
        } else {
          modal.close();
          removeExternalUserId();
          navigate("/login");
        }
        setLoading(false);
      })
      .catch(e => {
        simpleModal.errMsg(t("err.generalFail"), t("err.contactAdmin"));
        console.log("change pwd err", e);
        setLoading(false);
      });
  };

  return (
    <Modal isOpen={isOpen} onOk={handleOk} onCancel={handleOk} portalClassName={"changePasswordModal"}>
      <div className="content-container fontInter">
        <div className="title text-center">Reset password</div>
        <div>
          <Form form={form} onFinish={onFinish} onValuesChange={onValuesChange}>
            <Form.Item key="oldPwd" name="oldPwd" labelCol={{ span: 0 }} wrapperCol={{ span: 16, offset: 4 }}>
              <Input.Password placeholder={t("lbl.pwd")} />
            </Form.Item>
            <Form.Item key="pwd" name="pwd" labelCol={{ span: 0 }} wrapperCol={{ span: 16, offset: 4 }}>
              <Input.Password placeholder={t("lbl.newPwd")} />
            </Form.Item>
            <Form.Item key="password" name="password" labelCol={{ span: 0 }} wrapperCol={{ span: 16, offset: 4 }}>
              <Input.Password name="confirmPwd" placeholder={t("lbl.confirmPwd")} />
            </Form.Item>
            <PwdChecker email={userRes.data?.email} password={password} onCheckPwd={setIsValidFormat} className="smaller" />
            <Form.Item wrapperCol={{ span: 16, offset: 4 }}>
              <button className="btn yellow w-100 mt-3" disabled={!isValidFormat || !canSubmit || loading} onClick={onFinish}>
                {t("lbl.ok")}
              </button>
              <button className="btn plain yellow w-100 mt-3" onClick={handleOk}>
                {t("lbl.cancel")}
              </button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
}
