import { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import Modal from "react-modal";
import Dropdown from "../dropdown/Dropdown";

import { getSortedData, useErrorHandler, useMutationErrHandler, getTenantsQuery, changeTenantMutation } from "../../Gql";
import ModalLayout from "../modalLayout/ModalLayout";
import { useModal } from "../modalContext/ModalContext";
import { Utils } from "../../Utils";

export default function TenantModal({ isOpen, userRes }) {
  const { t } = useTranslation("common");

  const [selectedTenantId, setSelectedTenantId] = useState(null);

  const tenantsData = useQuery(getTenantsQuery);
  useErrorHandler(tenantsData);

  console.log("TenantModal", tenantsData);

  const modal = useModal();

  const [changeTenant, changeTenantData] = useMutation(changeTenantMutation);
  const errorHandler = useMutationErrHandler();

  const tenants = useMemo(() => getSortedData(tenantsData), [tenantsData]);

  useEffect(() => {
    setSelectedTenantId(userRes.data ? userRes.data.tenant.id : null);
  }, [userRes.data]);

  function handleChangeTenant(t) {
    if (t.id !== selectedTenantId) {
      setSelectedTenantId(t.id);
    }
  }

  function handleSave() {
    changeTenant({ variables: { id: selectedTenantId } }).then(() => {
      window.location.reload();
    }, errorHandler);
  }

  return (
    <ModalLayout isOpen={isOpen} modalContext={modal} modalContentClassName="p-5" modalClassName={"tenantModal"}>
      <div className="yellowFont big bold text-center mb-4">{t("lbl.tenant")}</div>
      <div>
        <Dropdown options={tenants} value={selectedTenantId} onChange={handleChangeTenant} handleColor="yellow" className="main bold" />
      </div>

      <div className="text-center mt-4">
        <button disabled={userRes.data?.tenant.id === selectedTenantId} onClick={handleSave} className="btn yellow round px-5">
          {t("lbl.ok")}
        </button>
      </div>
    </ModalLayout>
  );
}
