import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { useState, useCallback, useMemo } from "react";

import Spinner from "../spinner/Spinner";
import Polygon from "./Polygon";
import Device from "./Device";
import "./FloorPlan.scss";

//either pass in nodes or devices
//coolTemp/warmTemp is only for nodes
//selectedNodeId is only for nodes but is optional
//deviceStatusMap/deviceDisabledMap are only for devices, and is optional
//disabled: disable all onClick action for the whole map while deviceDisabledMap disable specific devices
//onClick(node), onClick(device)
export default function FloorPlan({ zone, nodes, selectedNodeId, coolTemp, warmTemp, devices, deviceStatusMap, deviceDisabledMap, disabled, onClick }) {
  console.log("FloorPlan");

  if (nodes && devices) {
    console.error("nodes and devices cannot be shown together!");
    return null;
  }

  const [isDarkMode, setIsDarkMode] = useState(false);
  const [containerSize, setContainerSize] = useState({});
  const [size, setSize] = useState({});
  const [transform, setTransform] = useState({ scale: 1, positionY: 0, positionX: 0 });
  const handleClick = disabled ? null : onClick;

  function onLoadImg(e) {
    setSize({ w: e.target.width, h: e.target.height });
  }

  function onTransformed(ref, e) {
    setTransform(e);
  }

  const elmRef = useCallback(container => {
    if (container) {
      const resizeObserver = new ResizeObserver(e => {
        setContainerSize({ w: e[0]?.contentBoxSize[0]?.inlineSize, h: e[0]?.contentBoxSize[0]?.blockSize });
      });
      resizeObserver.observe(container);
    }
  }, []);

  const darkLayerStyle = useMemo(() => {
    if (isDarkMode && containerSize.h != null && transform.scale != null) {
      return {
        height: containerSize.h / transform.scale + "px",
        width: containerSize.w / transform.scale + "px",
        top: -transform.positionY / transform.scale + "px",
        left: -transform.positionX / transform.scale + "px"
      };
    } else {
      return {
        display: "none"
      };
    }
  }, [isDarkMode, containerSize, transform]);

  //TODOricky check min/max position setting, add zoom and reset btn
  if (zone) {
    return (
      <>
        {/* set limtitToBounds false and remove zoomContainer size for fixing a style issue */}
        <div ref={elmRef} className={"floorPlan d-flex flex-column h-100" + (disabled ? " disabled" : "")}>
          <TransformWrapper
            minScale="0.2"
            initialScale={transform.scale}
            initialPositionX={transform.positionX}
            initialPositionY={transform.positionY}
            limitToBounds={false}
            onTransformed={onTransformed}
          >
            <TransformComponent wrapperStyle={{ flexBasis: 0, flexGrow: 1, width: "100%" }}>
              <div className="zoomContainer">
                {/*} style={{ height: size.h + "px", width: size.w + "px" }}>*/}
                <img src={zone.floorPlan} onLoad={onLoadImg} />
                <div className="darkLayer" style={darkLayerStyle}></div>
                {nodes && (
                  <svg width={size.w} height={size.h}>
                    {nodes.map(n => (
                      <Polygon key={n.id} isSelected={selectedNodeId === n.id} onClick={handleClick} node={n} coolTemp={coolTemp} warmTemp={warmTemp} />
                    ))}
                  </svg>
                )}
                {devices &&
                  devices.map(d => (
                    <Device key={d.id} onClick={handleClick} device={d} status={deviceStatusMap && deviceStatusMap[d.id]} disabled={deviceDisabledMap && deviceDisabledMap[d.id]} />
                  ))}
              </div>
            </TransformComponent>
          </TransformWrapper>
          {!nodes && !devices ? (
            <div className="blockLayer">
              <Spinner isCenter={true} height={"6rem"} />
            </div>
          ) : null}
          <button onClick={() => setIsDarkMode(!isDarkMode)} className="position-absolute darkModeBtn btn withBorder whiteBg white p-1">
            <div className={"p-25 icon grey " + (isDarkMode ? "lightOn" : "lightOff")}></div>
          </button>
        </div>
      </>
    );
  } else {
    return "";
  }
}
