import React, { useState, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useModal } from "../../modalContext/ModalContext";
import { useMutationErrHandler, useTurnOnNode, useExtendNode } from "../../../Gql";
import { Utils } from "../../../Utils";
import Spinner from "../../spinner/Spinner";

const TimerModalLarge = React.lazy(() => import("./TimerModalLarge"));

export default function TimerModal({ isOpen, node, isRm, isExtend, userRes, resolve, reject }) {
  const { t } = useTranslation("common");

  const [turnOnNode, turnOnNodeData] = useTurnOnNode(isRm);
  const [extendNode, extendNodeData] = useExtendNode(isRm);
  const errHandler = useMutationErrHandler();
  const errorHandler = resolve
    ? e => {
        resolve(false);
        errHandler(e);
      }
    : errHandler;
  const loading = turnOnNodeData.loading || extendNodeData.loading;

  console.log("TimerModal", isOpen);
  console.log("TimerModal(mutation)", turnOnNodeData, extendNodeData);

  const modal = useModal();
  const { csData, csDataIndex } = useMemo(() => getSliderData(userRes.data), [userRes.data]);

  const [value, setValue] = useState((csDataIndex + 1) * 5);
  const offTime = Utils.addMinute(node && isExtend ? node.schedule.nextOffAt : new Date(), value);
  const offTimeLbl = t("lbl.extendDurationVal", { time: Utils.timeFmt(offTime) });

  let desc = "";
  let question = "";
  if (isExtend) {
    if (isRm) {
      desc = t("lbl.extendRmDesc");
      question = t("lbl.extendRmQues");
    } else {
      desc = t("lbl.extendWsDesc");
      question = t("lbl.extendWsQues");
    }
  } else {
    if (isRm) {
      desc = t("lbl.onRmDesc");
      question = t("lbl.onRmQues");
    } else {
      desc = t("lbl.onWsDesc");
      question = t("lbl.onWsQues");
    }
  }

  function confirmHandler() {
    const controlNode = isExtend ? extendNode : turnOnNode;
    controlNode({ variables: { id: node.id, duration: value } }).then(() => {
      if (resolve) {
        resolve(true);
      }
      modal.close(); //this will trigger afterCloseHandler and resolve(false) again
      modal.turnOnMsg();
    }, errorHandler);
  }

  function afterCloseHandler() {
    if (resolve) {
      resolve(false);
    }
  }

  return (
    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
      {node && userRes.data ? (
        <TimerModalLarge
          isOpen={isOpen}
          csData={csData}
          csDataIndex={csDataIndex}
          setValue={setValue}
          loading={loading}
          desc={desc}
          question={question}
          offTimeLbl={offTimeLbl}
          confirmHandler={confirmHandler}
          afterCloseHandler={afterCloseHandler}
        />
      ) : (
        <Spinner isCenter={true} height={"15rem"} />
      )}
    </React.Suspense>
  );
}

function getSliderData(user) {
  const csData = new Array(user ? ((user.tenant.maxExtensionHours || 2) * 60) / 5 : 0);
  for (let i = 0; i < csData.length; i++) {
    csData[i] = i * 5 + 5;
  }
  const csDataIndex = Math.min(csData.length - 1, 5);
  return { csData, csDataIndex };
}

// <TimerModalSmall
//   isOpen={isOpen}
//   csData={csData}
//   csDataIndex={csDataIndex}
//   setValue={setValue}
//   loading={loading}
//   desc={desc}
//   question={question}
//   offTimeLbl={offTimeLbl}
//   confirmHandler={confirmHandler}
// />
