import ModalLayout from "../modalLayout/ModalLayout";
import Spinner from "../spinner/Spinner";
import { useModal } from "../modalContext/ModalContext";
import { useSimpleModal } from "../modalContext/SimpleModalContext";
import "./MsgModal.scss";

export default function MsgModal({ isOpen, msg, info, iconClass, onAfterClose, showLoading, isSimple, showClose }) {
  console.log("MsgModal");

  const modal = useModal();
  const simpleModal = useSimpleModal();
  const m = isSimple ? simpleModal : modal;

  return (
    <ModalLayout isOpen={isOpen} modalContext={m} modalClassName={"msgModal"} modalContentClassName="p-5" onAfterClose={onAfterClose}>
      <div className="pb-3">
        <div className={iconClass + " icon p-4"}></div>
      </div>
      <div className="text-center">
        <div className="mainFont big bold">{msg}</div>
        {info ? <div className="greyestFont bold pt-3">{info}</div> : null}
      </div>
      {showLoading ? (
        <div className="pt-4">
          <Spinner isCenter={true} size={"3.5rem"} />
        </div>
      ) : null}
      {showClose ? (
        <div className="text-center pt-4">
          <button className="btn round cross yellow p-3" onClick={m.close}></button>
        </div>
      ) : null}
    </ModalLayout>
  );
}
