//node
//listType (LIST_TYPE)
//onClick
//isSelected
export default function Polygon({ node, isSelected, warmTemp, coolTemp, onClick }) {
  console.log("Polygon", node.id);

  let cls = isSelected ? "selected " : "";
  if (node.roomTemperature) {
    if (node.roomTemperature > warmTemp) {
      cls += "warm";
    } else if (node.roomTemperature < coolTemp) {
      cls += "cool";
    } else {
      cls += "normal";
    }
  } else {
    cls += "noTemp";
  }

  if (node && node.locationPolygon) {
    return <polygon points={node.locationPolygon} className={cls} onClick={onClick ? () => onClick(node) : null} />;
  } else {
    return "";
  }
}
