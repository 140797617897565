import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route, useNavigate, useSearchParams } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Modal from "react-modal";

import "react-tooltip/dist/react-tooltip.css";

import PageLayout from "./pages/pageLayout/PageLayout";
import { ScrollEventProvider } from "./pages/pageLayout/ScrollEventContext";
import DashboardMainPortalPage from "./pages/mainPortalPublicPage/dashboardPage/dashboardPage";
import HandleMsCodePage from "./pages/signIn/handleMsCodePage/HandleMsCodePage";
import Handle401Page from "./pages/signIn/handle401Page/Handle401Page";
import LoginPage from "./pages/signIn/loginPage/LoginPage";
import { initOneSignal, print } from "./OneSignal";
import { UserProvider, useUser } from "./components/userContext/UserContext";
import { ModalProvider } from "./components/modalContext/ModalContext";
import { ApolloClientProvider } from "./components/apolloClientContext/ApolloClientContext";
import { SimpleModalProvider } from "./components/modalContext/SimpleModalContext";
import RouteElmWrapper from "./components/routeElmWrapper/RouteElmWrapper";
import { Utils } from "./Utils";
import { ACL_ROLE } from "./Constants";
import commonEn from "./translations/commonEn.json";
import commonZh from "./translations/commonZh.json";
import commonCn from "./translations/commonCn.json";
import Spinner from "./components/spinner/Spinner";

import "./variables.scss";
import "./index.scss";
import "./antdStyleRewrite.scss";
import "./myBootstrap.scss";

const CreateAccPage = React.lazy(() => import("./pages/signIn/createAccPage/CreateAccPage"));
const ActivateAccPage = React.lazy(() => import("./pages/signIn/activateAccPage/ActivateAccPage"));
const ForgotPwdPage = React.lazy(() => import("./pages/signIn/forgotPwdPage/ForgotPwdPage"));
const ResetPwdPage = React.lazy(() => import("./pages/signIn/resetPwdPage/ResetPwdPage"));
const CreatePwdPage = React.lazy(() => import("./pages/signIn/createPwdPage/CreatePwdPage"));
const ProfilePage = React.lazy(() => import("./pages/profilePage/ProfilePage"));
const AlertPage = React.lazy(() => import("./pages/alertPage/AlertPage"));
const LocationPage = React.lazy(() => import("./pages/locationPage/LocationPage"));
const AccountUserPage = React.lazy(() => import("./pages/mainPortalPublicPage/accountUserPage/AccountUserPage"));
const InsightsPage = React.lazy(() => import("./pages/insightsPage/insightsPage"));
const DashboardPage = React.lazy(() => import("./pages/dashboardPage/DashboardPage"));
const ScenePage = React.lazy(() => import("./pages/scenePage/ScenePage"));
const ControlPage = React.lazy(() => import("./pages/controlPage/ControlPage"));
const HandleTokenPage = React.lazy(() => import("./pages/signIn/handleTokenPage/HandleTokenPage"));
const HandleTokenErrorPage = React.lazy(() => import("./pages/signIn/handleTokenPage/HandleTokenErrorPage"));

//lang setting
i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: "en",
  resources: {
    en: { common: commonEn },
    zh: { common: commonZh },
    cn: { common: commonCn }
  }
});
Utils.loadLangCode();

//one signal
initOneSignal();
print();

//serve for 2 purposes
//1. redirect to home page for any invalid url
//2. handle redirect from ms login since ms cannot directly go to "/code"
//3. handle error when login ms sign in error (mostly for slient signIn)
function LandingPage() {
  console.log("LandingPage");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const error = searchParams.get("error");

  useEffect(() => {
    if (error) {
      Utils.clearSignInInfo();
      navigate("/login", { replace: true });
    } else if (code) {
      navigate(`/code/${code}`, { replace: true });
    } else {
      console.log("redirect from landing to home");
      navigate("/main/head/home", { replace: true });
    }
  });

  return <div></div>;
}

// different user may have different home page
// this will redirect them to the correct one
function HomePage() {
  console.log("HomePage");
  const navigate = useNavigate();
  const { userRes } = useUser();

  useEffect(() => {
    if (userRes.data) {
      if (userRes.data.tenant?.enableTep) {
        navigate("/main/head/control", { replace: true });
      } else {
        navigate("/main/head/dashboard", { replace: true });
      }
    }
  }, [userRes]);

  return <div></div>;
}

Modal.setAppElement("#root");

const LocationPageElm = (
  <RouteElmWrapper minRole={ACL_ROLE.zoneAdmin} isTep={true}>
    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
      <LocationPage />
    </React.Suspense>
  </RouteElmWrapper>
);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <SimpleModalProvider>
          <ApolloClientProvider>
            <ModalProvider>
              <Routes>
                <Route path="/main" element={<UserProvider />}>
                  {/* all route under 'head' will have header and sidemenu */}
                  <Route path="head" element={<PageLayout />}>
                    <Route path="home" element={<HomePage />} />
                    <Route
                      path="control"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.tenantUser} isTep={true}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <ControlPage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route path="location" element={LocationPageElm}>
                      <Route path=":defaultZoneId" element={LocationPageElm}>
                        <Route path=":defaultNodeId" element={LocationPageElm} />
                      </Route>
                    </Route>
                    <Route
                      path="sceneSetting"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.zoneAdmin} isTep={true}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <ScenePage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="scene/:isRm/:nodeId"
                      element={
                        //this is scene control when user click the control card
                        <RouteElmWrapper minRole={ACL_ROLE.tenantUser} isTep={true}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <ScenePage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="account"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.zoneAdmin}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <ScrollEventProvider triggerScrollEnd={true}>
                              <AccountUserPage />
                            </ScrollEventProvider>
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="insights"
                      element={
                        <RouteElmWrapper requiredRoles={[ACL_ROLE.student, ACL_ROLE.dashboardAdmin]}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <InsightsPage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="dashboard"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.tenantUser}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <DashboardPage />
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route
                      path="profile"
                      element={
                        <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                          <ProfilePage />
                        </React.Suspense>
                      }
                    />
                    <Route
                      path="alert"
                      element={
                        <RouteElmWrapper minRole={ACL_ROLE.tenantUser}>
                          <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                            <ScrollEventProvider triggerScrollEnd={true}>
                              <AlertPage />
                            </ScrollEventProvider>
                          </React.Suspense>
                        </RouteElmWrapper>
                      }
                    />
                    <Route index element={<LandingPage />} />
                  </Route>
                  {/* for embedding use only, no header and sidemenu */}
                  <Route
                    path="control"
                    element={
                      <RouteElmWrapper minRole={ACL_ROLE.tenantUser} isTep={true}>
                        <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                          <ControlPage isBgDark={true} />
                        </React.Suspense>
                      </RouteElmWrapper>
                    }
                  />
                  <Route
                    path="accountUserPublic"
                    element={
                      <RouteElmWrapper minRole={ACL_ROLE.zoneAdmin}>
                        <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                          <ScrollEventProvider triggerScrollEnd={true} inIframe={true}>
                            <AccountUserPage isStarbucks={true} />
                          </ScrollEventProvider>
                        </React.Suspense>
                      </RouteElmWrapper>
                    }
                  />
                  <Route path="dashboardPublic" element={<DashboardMainPortalPage />} />
                  <Route index element={<LandingPage />} />
                </Route>
                <Route path="/login" element={<LoginPage />} />
                <Route
                  path="/reset" //?email=&code=
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <ResetPwdPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/forgot"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <ForgotPwdPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/createPwd/:email/:session/:userpool"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <CreatePwdPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/new"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <CreateAccPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/activate/:email/:token"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <ActivateAccPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/token/:externalTokenType/:externalIdToken"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <HandleTokenPage />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/tokenError"
                  element={
                    <React.Suspense fallback={<Spinner isCenter={true} height={"15rem"} />}>
                      <HandleTokenErrorPage />
                    </React.Suspense>
                  }
                />
                <Route path="/code/:code" element={<HandleMsCodePage />} />
                <Route path="/redirect/:path" element={<Handle401Page />} />
                <Route path="*" element={<LandingPage />} />
              </Routes>
            </ModalProvider>
          </ApolloClientProvider>
        </SimpleModalProvider>
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
