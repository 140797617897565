import { useTranslation } from "react-i18next";

import ModalLayout from "../../modalLayout/ModalLayout";
import { useModal } from "../../modalContext/ModalContext";

//share use
export default function TemperatureLockModal({ isOpen, minute, onAfterClose }) {
  console.log("TemperatureLockModal");

  const { t } = useTranslation("common");
  const modal = useModal();
  const displayMinute = minute == 0 ? 1 : minute; //dont show 0 minute
  const msg = minute < 0 ? t("lbl.lockMinuteUnkown") : t("lbl.lockMinute", { minute: displayMinute });

  return (
    <ModalLayout isOpen={isOpen} modalContext={modal} modalClassName={"msgModal"} modalContentClassName="p-5" onAfterClose={onAfterClose}>
      <div className="pb-3">
        <div className="icon stop p-45"></div>
      </div>
      <div className="text-center">
        <div className="mainFont bold">{t("lbl.lockDesc")}</div>
        <div className="greyestFont small pt-3">{msg}</div>
      </div>
      <div className="text-center pt-4">
        <button className="btn round cross yellow p-3" onClick={modal.close}></button>
      </div>
    </ModalLayout>
  );
}
